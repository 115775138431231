body {
  font-family: 'Varela Round', sans-serif !important;
  background: #333333 !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  color: #FFFFFF !important;
}

body::-webkit-scrollbar {
  display: none !important;
}

.no-scroll-bar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.no-scroll-bar::-webkit-scrollbar {
  display: none !important;
}

.neumorphic-box {
  border-radius: 50px;
  background: #383838;
  box-shadow:  5px 5px 10px #313131,
  -5px -5px 10px #3f3f3f;
  transition: box-shadow 0.5s ease-in;
}

.neumorphic-box:hover {
  box-shadow:  10px 10px 20px #313131,
  -10px -10px 20px #3f3f3f;
}